// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// Css Happybrain

.MuiButton-containedPrimary,
.MuiButton-containedSecondary {
    box-shadow: none !important;
}
.MuiButton-containedPrimary {
    color: white !important;
}

.MuiTableBody-root {
    td {
        font-size: 13px;
    }
}
.MuiTableHead-root {
    th {
        line-height: 15px;
        font-size: 13px!important;
    }
}
.MuiTypography-caption,
.MuiTypography-h6,
.MuiTypography-h5 {
    font-weight: 600;
}

.MuiTablePagination-root {
    * {
        font-size:13px;
    }
    .MuiTablePagination-actions button svg {
        font-size:18px;
    }
}
.MuiTab-root {
    font-size:13px;
}

.stack-panel {
    h6 {
       font-size: 13px;
    }
    p {
        line-height: 1.75;
        font-size: 13px;
    }
}

.MuiTabPanel-root {
    padding: 0;
}

.stepper {
    .MuiStepLabel-iconContainer svg text {
        fill: white;
        font-weight: bold;
    }
}

.status-head {
    h5 {
        font-size: 0.8rem;
    }
}
.MuiTimelineDot-filled circle {
    fill: white;
}
.MuiTimelineConnector-root {
    width: 1px;
}
.MuiTimelineItem-root .MuiTypography-body1 {
    font-size: 13px;
    color: #666;
}
.notication {
    .MuiListItem-container {
        display: flex;
        align-items: center;
        .MuiListItem-secondaryAction {
            width: 70%;
        }
        .MuiListItemSecondaryAction-root {
            width: 30%;
            position: relative;
            top: 0;
            transform: none;
            text-align: right;
            span {
                margin-top: 4px;
                display: block;
                line-height: 15px;
            }
        }
    }

    .number {
        font-size: 0.75rem;
        font-weight: 600;
        color: $primaryMain;
    }

    .MuiListItemText-root p {
        font-weight: 600;
        font-size: 15px !important;
        padding-right: 5px;
    }
}

.MuiChip-label {
    font-weight: 600;
    font-size: 12px;
}

.MuiTableHead-root th {
    padding: 10px 5px;
}

.MuiTableBody-root td {
    padding: 5px;
}

.ObjectList_StatusSelect {
  display: inline-block;
  position: relative;

  select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

}
